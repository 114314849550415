// TODO: will probably need refactoring when this part is defined
// Not clear whose name should be here, what is `cost` and `status`

export type CollegeMemberType = {
  id: any
  name: string
  startDate: string
  endDate: string
  cost: string
  status: CollegeMembershipStatus
}

export enum CollegeMembershipStatus {
  status1 = 'status1',
  status2 = 'status2'
}

const createData = (
  id,
  name: string,
  startDate: string,
  endDate: string,
  cost: string,
  status: CollegeMembershipStatus
) => {
  return { id, name, startDate, endDate, cost, status }
}

export const allMembers: CollegeMemberType[] = [
  createData(
    1,
    'William Low',
    '06/01/2020',
    '05/31/2021',
    '100$',
    CollegeMembershipStatus.status1
  )
]