import React from 'react'
import { usePrivateRoute } from 'src/utils/auth'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import CollegeMembershipHome from 'src/components/college-membership-home/college-membership-home'

const IndexPage: React.FC = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="College Memberships" />
      <CollegeMembershipHome />
    </Layout>
  )
}

export default IndexPage