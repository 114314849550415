import React from 'react'

import * as styles from 'src/components/table-panel-title/table-panel-title.module.less'
import Panel from 'src/components/panel/panel'

interface TablePanelTitleProps {
  title: string
}

const TablePanelTitle: React.FC<TablePanelTitleProps> = ({ title }) => {
  return (
    <Panel.Title>
      <span className={styles.capitalise}>{title}</span>
    </Panel.Title>
  )
}

export default TablePanelTitle