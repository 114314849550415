import React from 'react'

import Grid from '@material-ui/core/Grid'

import CollegeMembershipTabs from 'src/components/college-membership-tabs/college-membership-tabs'

const CollegeMembershipHome: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <CollegeMembershipTabs />
      </Grid>
    </Grid>
  )
}

export default CollegeMembershipHome