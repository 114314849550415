import React from 'react'
import MUITabs from 'src/components/tabs/tabs'
import { Grid } from '@material-ui/core'
import TabPanel from 'src/components/tab-panel/tab-panel'
import { useTranslation } from 'react-i18next'
import CollegeMembershipActiveMembershipsTab
  from 'src/components/college-membership-active-memberships-tab/college-membership-active-memberships-tab'

const CollegeMembershipTabs: React.FC = () => {
  const { t } = useTranslation()
  const [ value, setValue ] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <MUITabs
        value={value}
        labels={[ t('membership_plural') ]}
        handleChange={handleChange}
      />
      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          <CollegeMembershipActiveMembershipsTab />
        </TabPanel>
      </Grid>
    </>
  )
}

export default CollegeMembershipTabs